<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 804.94 150"
    ><path
        d="M49.84 98.87H11.97l47.87 47.86 47.86-47.86H69.84V38.38h735.1v-20H49.84v80.49z"
        class="cls-1"
    /></svg>
</template>
<style>
.cls-1 {
    @apply fill-current;
    stroke-width: 0;
}
</style>
