<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 804.94 150"
    ><path
        d="M751.58 98.87V18.38H-3.52v20h735.1v60.49h-37.86l47.86 47.86 47.87-47.86h-37.87z"
        class="cls-1"
    /></svg>
</template>
<style>
.cls-1 {
    @apply fill-current;
    stroke-width: 0;
}
</style>
