<template>
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 100 100"
    >
        <path
            d="M49.61 90.04c.11.04.33.06.44.06s.23-.02.35-.06c24.26-8.93 37.09-31.91 37.09-66.44 0-.3-.14-.59-.37-.78-9.32-7.57-20.57-11.98-32.56-12.75-.16-.01-.32-.02-.48-.02-.12 0-.23 0-.36-.02-2.22-.13-5.28-.13-7.45 0-.12 0-.24.01-.35.02-.16 0-.32.01-.47.02-11.98.77-23.25 5.18-32.57 12.75-.23.19-.37.47-.37.78 0 34.54 12.83 57.51 37.09 66.44ZM45.6 12.07c.13-.01.26-.01.39-.02.14 0 .29 0 .42-.02 2.09-.12 5.05-.13 7.19 0 .14 0 .28.01.42.02.13 0 .27 0 .4.02h.02c11.41.73 22.14 4.88 31.06 12.01-.12 33.21-12.38 55.32-35.48 63.97-23.13-8.65-35.39-30.76-35.5-63.98 8.92-7.13 19.65-11.28 31.08-12.01Z"
            class="cls-1"
        />
        <path
            d="M49.58 79.41a1.023 1.023 0 0 0 .84 0c17.02-7.85 26.15-24.98 27.15-50.91.01-.35-.16-.69-.46-.88a48.918 48.918 0 0 0-23.37-7.76l-.6-.03c-.96-.06-1.99-.11-3.25-.11-1.04 0-2.09.06-2.98.11l-.76.03c-8.27.56-16.31 3.24-23.27 7.75-.3.19-.47.53-.45.88 1 25.93 10.14 43.06 27.15 50.91Zm-3.32-57.54.75-.03c.88-.05 1.89-.11 2.87-.11 1.2 0 2.21.05 3.15.11l.59.03c7.77.51 15.33 2.98 21.92 7.13C74.47 53.58 65.88 69.86 50 77.4 34.12 69.86 25.52 53.58 24.45 29c6.56-4.14 14.09-6.6 21.81-7.13Z"
            class="cls-1"
        />
        <path
            d="m45.22 47.62-1.73 11.11c-.04.29.04.58.23.8.19.22.47.35.76.35h11.05c.29 0 .57-.13.76-.35.19-.22.27-.52.23-.8l-1.73-11.11a7.754 7.754 0 0 0 3.03-6.17c0-2.09-.81-4.05-2.28-5.53a7.747 7.747 0 0 0-5.52-2.3h-.01c-4.3 0-7.81 3.5-7.82 7.8 0 2.45 1.12 4.72 3.03 6.2Zm4.78-12h.01c1.55 0 3.01.61 4.11 1.71 1.1 1.1 1.7 2.56 1.7 4.12 0 1.98-1 3.8-2.66 4.88-.33.21-.51.6-.45.99l1.64 10.57h-8.71l1.64-10.57a.98.98 0 0 0-.45-.99 5.805 5.805 0 0 1-2.66-4.9c0-3.2 2.61-5.81 5.82-5.81Z"
            class="cls-1"
        />
    </svg>
</template>
<style>
.cls-1 {
    @apply fill-current;
    stroke-width: 0;
}
</style>
