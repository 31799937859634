<script setup>
import Layout from '@/Layouts/GeneralLayout.vue'
import { ref } from 'vue'
import { Biomedical, ForkLift, LargeArrowLeft, LargeArrowRight, SalesReps, JetArrows, Paperwork, PriceTags, Security, Technology } from '@/Components/Icons'
import { CloudArrowDownIcon, ChatBubbleBottomCenterIcon, MagnifyingGlassPlusIcon } from '@heroicons/vue/24/solid'
import IconHeading from '@/Components/IconHeading.vue'
import Carousel from '@/Components/Carousels/Template.vue'

const vendor = ref(false)
const customer = ref(false)

function resetHover () {
    vendor.value = false
    customer.value = false
}

defineOptions({ layout: Layout })
</script>

<template>
    <Head>
        <title>M1 Financial: Equipment Leasing & Financing Company</title>
        <meta
            name="description"
            content="M1 Financial is a trusted equipment finance company ready to help you with any of your financing needs. With our years of experience, we offer custom and creative customer financing and vendor financing."
            head-key="description"
        />
        <link
            rel="canonical"
            :href="route('home')"
        />
        <component
            :is="'script'"
            type="application/ld+json"
        >
            {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "M1 Financial",
            "description": "At M1 Financial we empower companies by providing unparalleled financial services that
            transcend traditional boundaries. With decades of combined experience, M1 specializes in crafting unique
            structures with flexible terms designed specifically for the companies and customers we serve.We are your
            friendly and trustworthy partner and our commitment to creativity, simplicity, and responsiveness ensures a
            lasting and
            committed relationship.",
            "image":
            "https://res.cloudinary.com/med-one-group/image/upload/c_scale,q_auto,w_444/v1695827675/M1Financial/logo.svg",
            "logo":
            "https://res.cloudinary.com/med-one-group/image/upload/c_scale,q_auto,w_444/v1695827675/M1Financial/logo.svg",
            "url": "https://www.m1financial.com/",
            "telephone": "888-959-3500",
            "sameAs": [
            "https://twitter.com/m1financial",
            "https://www.linkedin.com/company/m1-financial",
            " https://www.facebook.com/profile.php?id=61550577772224",
            "https://www.instagram.com/m1financial_/"
            ],
            "address": {
            "@type": "PostalAddress",
            "streetAddress": "CORPORATE 10712 South 1300 East ",
            "addressLocality": "Sandy",
            "postalCode": "84094",
            "addressCountry": "United States"
            }
            }
        </component>
    </Head>
    <!-- spacer -->
    <div class="w-full py-8 lg:py-9"></div>
    <!-- Carousel -->
    <header class="h-[30rem] lg:h-[36rem]">
        <Carousel />
    </header>
    <div
        id="flashBanner"
        class="p-4 space-y-2 text-center bg-gray-50 lg:text-xl"
    >
        <h4 class="font-bold">
            Expanding Beyond Healthcare: M1 Financial Delivers Customized Financial Solutions for Diverse Business Needs
            -
            <Link
                class="inline-block font-semibold border-b text-red-primary border-red-primary"
                :href="route('press release')"
            >
                VIEW THE PRESS RELEASE
            </Link>
        </h4>
    </div>
    <section class="px-6 mx-auto mb-20 max-w-8xl">
        <div>
            <h1 class="mt-16 mb-10 text-3xl font-bold text-center text-red-primary lg:text-4xl">
                M1 Financial - Equipment
                Leasing and Financing Company
            </h1>
        </div>
        <p class="max-w-5xl mx-auto mb-10 text-center lg:text-xl">
            <strong>As a seasoned lending partner,</strong> we can help increase accessibility and acquisitions
            for manufacturers or business owners alike. No matter the industry, M1 is here to
            offer custom and creative finance options based on your needs.
        </p>
        <div class="relative mx-auto w-fit">
            <aos-vue animation="fade-left"></aos-vue>
            <LargeArrowLeft
                data-aos="fade-right"
                class="absolute w-32 text-gray-200 duration-300 max-md:hidden -left-32 xl:-left-44 2xl:-left-60 top-6 xl:w-44 2xl:w-60"
            />
            <div class="relative z-10 max-w-sm px-4 py-3 mx-auto">
                <h3 class="text-2xl font-bold text-center text-red-primary lg:text-3xl">
                    Which Describes You?
                </h3>
            </div>
            <LargeArrowRight
                data-aos="fade-left"
                class="absolute w-32 text-gray-200 duration-300 max-md:hidden -right-32 xl:-right-44 2xl:-right-60 top-6 xl:w-44 2xl:w-60"
            />
        </div>
        <div
            data-aos="fade-up"
            data-aos-delay="300"
            class="grid gap-10 mx-auto mt-6 min-h-96 md:gap-10 lg:gap-20 md:grid-cols-2 md:mt-10"
        >
            <!-- vendor -->
            <div
                class="group min-h-60 hover:scale-105 hover:shadow-lg duration-300 relative rounded-xl overflow-hidden bg-[url(https://res.cloudinary.com/med-one-group/image/upload/c_scale,q_auto,w_700/v1698793028/M1Financial/Home/i-sell-equipment.webp)] bg-right bg-cover hover:bg-red-primary bg-blend-multiply"
                :class="{ 'lg:scale-95 lg:grayscale opacity-80': customer }"
                @mouseover="vendor = true"
                @mouseleave="resetHover"
            >
                <Link
                    :href="route('why m1', { type: 'vendors' })"
                    class="absolute top-0 left-0 flex flex-col justify-center w-full h-full gap-6 p-10 text-center text-white duration-300 opacity-0 group-hover:opacity-100"
                >
                    <p class="max-w-sm mx-auto text-sm lg:text-base">
                        Looking to increase your equipment sales? Our equipment
                        finance vendor programs are designed to boost your selling power, offering flexible terms to your
                        customers.
                    </p>
                    <div class="underline">
                        <p>LEARN MORE</p>
                    </div>
                </Link>
                <div
                    class="absolute left-0 right-0 z-40 flex items-center justify-center mx-auto text-xl text-center text-white duration-500 bottom-10 xl:text-2xl group-hover:hidden"
                >
                    <h3 class="block font-bold">
                        I AM SELLING EQUIPMENT
                    </h3>
                    <MagnifyingGlassPlusIcon class="w-8 ml-4" />
                </div>
            </div>

            <!-- Customer -->
            <div
                class="group min-h-60 hover:scale-105 hover:shadow-lg duration-300 relative rounded-xl overflow-hidden bg-[url(https://res.cloudinary.com/med-one-group/image/upload/c_scale,q_auto,w_700/v1721663869/M1Financial/Home/i-need-equipment-light.webp)] bg-center bg-cover hover:bg-red-primary bg-blend-multiply"
                :class="{ 'lg:scale-95 lg:grayscale opacity-80': vendor }"
                @mouseover="customer = true"
                @mouseleave="resetHover"
            >
                <Link
                    :href="route('why m1', { type: 'customers' })"
                    class="absolute top-0 left-0 flex flex-col justify-center w-full h-full gap-6 p-10 text-center text-white duration-300 opacity-0 group-hover:opacity-100"
                >
                    <p class="max-w-sm mx-auto text-sm lg:text-base">
                        In need of essential equipment to expand your business?
                        We provide tailored financing options to
                        keep your <strong>financing for equipment</strong> within budget.
                    </p>
                    <div class="underline">
                        <p>LEARN MORE</p>
                    </div>
                </Link>
                <div
                    class="absolute left-0 right-0 z-40 flex items-center justify-center mx-auto text-xl text-center text-white duration-500 bottom-10 xl:text-2xl group-hover:hidden"
                >
                    <h3 class="block font-bold">
                        I AM BUYING EQUIPMENT
                    </h3>
                    <MagnifyingGlassPlusIcon class="w-8 ml-4" />
                </div>
            </div>
        </div>
    </section>
    <section class="bg-gray-100 py-14">
        <div class="px-4 mx-auto space-y-10 max-w-7xl">
            <div class="max-w-5xl mx-auto space-y-10 text-center">
                <h2 class="text-2xl font-bold lg:text-3xl text-red-primary">
                    Key Benefits of Partnering with M1 Financial
                </h2>
                <p class="lg:text-xl">
                    With over 30 years of experience, M1 Financial has the expertise to meet your
                    financing needs. M1
                    Financial boasts high approval rates, operates exclusively with corporate clients, and can extend
                    terms
                    up to 84 months. We effectively assist vendors in boosting their sales and empower customers to
                    obtain
                    the necessary equipment.
                </p>
            </div>
            <div class="grid md:grid-cols-2 xl:grid-cols-4 gap-14">
                <IconHeading
                    data-aos="zoom-in"
                    title="DEDICATED SALES REP"
                >
                    <SalesReps />
                    <template #heading>
                        Your sales rep will provide personalized support, simplifying the process while always keeping
                        you informed. We aim to make your experience as stress-free and transparent as possible.
                    </template>
                </IconHeading>
                <IconHeading
                    data-aos="zoom-in"
                    data-aos-delay="300"
                    title="fast turn around"
                >
                    <JetArrows />
                    <template #heading>
                        We honor your valuable time with swift processing, allowing you to move quickly from application
                        to approval.
                    </template>
                </IconHeading>
                <IconHeading
                    data-aos="zoom-in"
                    data-aos-delay="600"
                    title="SIMPLE DOCUMENTATION"
                >
                    <Paperwork />
                    <template #heading>
                        Experience the ease of straightforward paperwork that's easy to
                        understand, with no hidden fees.
                    </template>
                </IconHeading>
                <IconHeading
                    data-aos="zoom-in"
                    data-aos-delay="900"
                    title="FLEXIBLE PRICING"
                >
                    <PriceTags />
                    <template #heading>
                        We create customized financing solutions that fit your unique needs, providing
                        <strong>vendors</strong> and <strong>customers</strong> with flexible and innovative payment
                        plans.
                    </template>
                </IconHeading>
            </div>
        </div>
    </section>
    <section class="relative py-20 mx-auto xl:max-w-7xl">
        <h2 class="mb-10 text-2xl font-bold text-center lg:text-3xl">
            Our Simple Process
        </h2>
        <hr
            data-aos="flip-left"
            data-aos-duration="1500"
            data-aos-delay="1300"
            class="max-lg:hidden left-0 right-0 mx-auto border-2 border-red-primary absolute top-[52%] w-9/12 -z-10"
        />
        <div class="grid gap-10 px-5 sm:grid-cols-2 lg:grid-cols-4">
            <Link
                data-aos="fade-right"
                :href="route('how it works', { type: 'vendors', id: 'one' })"
            >
                <div>
                    <div class="mx-auto w-68 h-68">
                        <img
                            class="object-contain w-full h-full processNumber"
                            src="https://res.cloudinary.com/med-one-group/image/upload/q_auto/v1699457930/M1Financial/process-img-step-1.png"
                        />
                    </div>
                    <p class="processTitle">
                        SIMPLE<br>APPLICATION
                    </p>
                </div>
            </Link>
            <Link
                data-aos="fade-right"
                data-aos-delay="300"
                :href="route('how it works', { type: 'vendors', id: 'two' })"
            >
                <div>
                    <div class="mx-auto w-68 h-68">
                        <img
                            class="object-contain w-full h-full processNumber"
                            src="https://res.cloudinary.com/med-one-group/image/upload/q_auto/v1699457930/M1Financial/process-img-step-2.png"
                        />
                    </div>
                    <p class="processTitle">
                        QUICK CREDIT<br>DECISION
                    </p>
                </div>
            </Link>
            <Link
                data-aos="fade-right"
                data-aos-delay="600"
                :href="route('how it works', { type: 'vendors', id: 'three' })"
            >
                <div>
                    <div class="mx-auto w-68 h-68">
                        <img
                            class="object-contain w-full h-full processNumber"
                            src="https://res.cloudinary.com/med-one-group/image/upload/q_auto/v1699457930/M1Financial/process-img-step-3.png"
                        />
                    </div>
                    <p class="processTitle">
                        SIGN<br>DOCUMENTS
                    </p>
                </div>
            </Link>
            <Link
                data-aos="fade-right"
                data-aos-delay="900"
                :href="route('how it works', { type: 'vendors', id: 'four' })"
            >
                <div>
                    <div class="mx-auto w-68 h-68">
                        <img
                            class="object-contain w-full h-full processNumber"
                            src="https://res.cloudinary.com/med-one-group/image/upload/q_auto/v1699457930/M1Financial/process-img-step-4.png"
                        />
                    </div>
                    <p class="processTitle">
                        DEAL CLOSED<br>& PAYMENT
                    </p>
                </div>
            </Link>
        </div>
    </section>
    <section
        class="text-white py-24 px-10 bg-[url(https://res.cloudinary.com/med-one-group/image/upload/q_auto,b_black,o_30/v1701798089/M1Financial/Home/learn-more.webp)] bg-fixed bg-cover bg-top"
    >
        <div class="flex flex-col items-center justify-center gap-4 xl:flex-row">
            <h3 class="px-4 font-medium text-center lg:text-xl xl:text-left">
                WANT TO LEARN MORE? DOWNLOAD OUR BROCHURE OR
                <span class="whitespace-nowrap">CONTACT US ANYTIME</span>
            </h3>
            <div class="flex flex-col items-center justify-center w-full max-w-md gap-4 sm:flex-row">
                <a
                    class="group bg-red-primary py-2 block w-full max-w-[15rem] rounded-md text-center border-2 border-transparent hover:border-white duration-300"
                    href="https://res.cloudinary.com/med-one-group/image/upload/q_auto/v1701982457/M1Financial/pdf/General-Leasing-M1_Financial.pdf"
                    target="_blank"
                >
                    <div class="relative flex items-center px-4 overflow-hidden text-sm">
                        <CloudArrowDownIcon
                            class="w-6 duration-300 -rotate-90 -translate-x-10 group-hover:rotate-0 group-hover:translate-x-0"
                        />
                        <p class="font-medium duration-500 group-hover:translate-x-4">DOWNLOAD PDF</p>
                    </div>
                </a>
                <Link
                    class="group bg-red-primary py-2 block w-full max-w-[15rem] rounded-md text-center border-2 border-transparent hover:border-white duration-300"
                    :href="route('contact')"
                >
                    <div class="relative flex items-center px-4 overflow-hidden text-sm">
                        <ChatBubbleBottomCenterIcon
                            class="w-6 duration-300 -rotate-90 -translate-x-20 group-hover:rotate-0 group-hover:translate-x-2"
                        />
                        <p class="font-medium duration-500 translate-x-4 group-hover:translate-x-6">
                            CONTACT US
                        </p>
                    </div>
                </Link>
            </div>
        </div>
    </section>
    <section class="py-20 mx-auto lg:max-w-7xl">
        <h2 class="text-2xl font-bold text-center lg:text-3xl mb-14">
            Target Industries We Serve
        </h2>
        <div class="grid justify-center px-5 sm:grid-cols-2 lg:grid-cols-4 gap-14">
            <IconHeading
                data-aos="zoom-in-right"
                title="MATERIAL HANDLING"
                link-to="material handling"
            >
                <ForkLift />
                <template #heading>
                    Equip your operations with the latest machinery, optimizing efficiency and productivity with our
                    flexible financing for equipment.
                </template>
            </IconHeading>
            <IconHeading
                data-aos="zoom-in-right"
                data-aos-delay="300"
                title="TECHNOLOGY"
                link-to="technology"
            >
                <Technology />
                <template #heading>
                    Stay ahead of the curve with cutting-edge technology upgrades made accessible through our tailored
                    vendor equipment financing options, ensuring innovation without financial strain.
                </template>
            </IconHeading>
            <IconHeading
                data-aos="zoom-in-right"
                data-aos-delay="600"
                title="SECURITY"
                link-to="security"
            >
                <Security />
                <template #heading>
                    Enhance safety measures and project your assets with state-of-the-art security equipment, made
                    affordable through our equipment leasing company.
                </template>
            </IconHeading>
            <IconHeading
                data-aos="zoom-in-right"
                data-aos-delay="900"
                title="LAB EQUIPMENT"
                link-to="lab equipment"
            >
                <Biomedical />
                <template #heading>
                    Empower research and development initiatives with customizable financing for equipment, enabling
                    innovative research and development without financial constraints.
                </template>
            </IconHeading>
        </div>
    </section>
</template>
<style>
.angledCont {
    @apply relative z-10 sm:-left-4 hover:left-0 transition-all ease-in-out bg-[#697c7f] border-t-2 sm:border-r-2 border-b-2 border-gray-100 py-6 pl-10 md:pl-20 pr-10;
}

.heading {
    @apply text-red-primary text-center uppercase text-5xl;
}

.btn {
    @apply px-5 py-2 border-red-primary rounded-md border-2;
}

.btn.btn-primary {
    @apply text-white bg-red-primary hover:text-red-primary hover:bg-red-secondary duration-300;
}

.btn.hollow {
    @apply text-red-primary hover:text-white hover:bg-red-primary duration-300;
}

@media only screen and (min-width: 520px) {
    .angledCont:after {
        position: absolute;
        z-index: -1;
        content: "";
        right: -4%;
        top: -2px;
        height: 102.9%;
        width: 8%;
        background-color: inherit;
        border: inherit;
        -webkit-transform: skewX(-13deg);
        -moz-transform: skewX(-13deg);
        -ms-transform: skewX(-13deg);
        transform: skewX(-13deg);
    }
}

.processNumber {
    @apply mx-auto mb-1 w-full max-w-xs p-2 hover:p-0 duration-300;
}

.processTitle {
    @apply font-bold text-lg text-center;
}

.industryIcon {
    @apply w-auto h-32 mx-auto;
}
</style>
